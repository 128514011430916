import { computed } from 'vue';
import { CampaignNextCondition, CampaignStep } from '@/components/Campaigns/CampaignsTypes';
import CampaignsCreateStepsViewsLinkedinInvitation
  from '@/components/Campaigns/CampaignsCreate/CampaignsCreateSteps/CampaignsCreateStepsViews/CampaignsCreateStepsViewsLinkedinInvitation.vue';
import CampaignsCreateStepsViewsLinkedinMessage
  from '@/components/Campaigns/CampaignsCreate/CampaignsCreateSteps/CampaignsCreateStepsViews/CampaignsCreateStepsViewsLinkedinMessage.vue';
import CampaignsCreateStepsViewsLinkedinInmailRecruiter
  from '@/components/Campaigns/CampaignsCreate/CampaignsCreateSteps/CampaignsCreateStepsViews/CampaignsCreateStepsViewsLinkedinInmailRecruiter.vue';
import CampaignsCreateStepsViewsLinkedinInmailSales
  from '@/components/Campaigns/CampaignsCreate/CampaignsCreateSteps/CampaignsCreateStepsViews/CampaignsCreateStepsViewsLinkedinInmailSales.vue';
import CampaignsCreateStepsViewsLinkedinInmailPremium
  from '@/components/Campaigns/CampaignsCreate/CampaignsCreateSteps/CampaignsCreateStepsViews/CampaignsCreateStepsViewsLinkedinInmailPremium.vue';
import CampaignsCreateStepsViewsLinkedinProfileVisit
  from '@/components/Campaigns/CampaignsCreate/CampaignsCreateSteps/CampaignsCreateStepsViews/CampaignsCreateStepsViewsLinkedinProfileVisit.vue';
import CampaignsCreateStepsViewsSendMail
  from '@/components/Campaigns/CampaignsCreate/CampaignsCreateSteps/CampaignsCreateStepsViews/CampaignsCreateStepsViewsSendMail.vue';
import { useI18n } from '@/i18n/i18n';

export const useCampaignsActions = () => {
  const { t } = useI18n();

  const conditions = computed<CampaignNextCondition[]>(() => [
    {
      key: 'INVITATION_ACCEPTED',
      name: t('campaigns.conditions.accepted-invitation'),
    },
    // {
    //   key: 'MESSAGE_RECEIVED',
    //   name: t('campaigns.conditions.linkedin-answer'),
    // },
    {
      key: 'MAIL_ANSWER',
      name: t('campaigns.conditions.mail-answer'),
    },
    {
      key: 'PROFILE_VISITED',
      name: t('campaigns.conditions.profile-visited'),
    },
    // {
    //   key: 'PROCESSED_CANDIDATE',
    //   name: t('campaigns.conditions.processed-candidate'),
    // },
    {
      key: 'DELAY',
      name: t('campaigns.conditions.delay'),
    },
    {
      key: 'IMMEDIATELY_AFTER_STEP',
      name: t('campaigns.conditions.immediately-after-step'),
    },
  ]);

  function getConditionFromKey(key: CampaignNextCondition['key']): CampaignNextCondition {
    return conditions.value.find((condition) => condition.key === key);
  }

  const steps = computed<CampaignStep[]>(() => [
    {
      key: 'LINKEDIN_INVITATION',
      name: t('campaigns.steps.linkedin-invitation'),
      icon: 'linkedin-square',
      iconColor: '#EEF3F8',
      backgroundColor: '#0A66C2',
      component: CampaignsCreateStepsViewsLinkedinInvitation,
      nextConditions: [
        getConditionFromKey('INVITATION_ACCEPTED'),
      ],
    },
    {
      key: 'LINKEDIN_MESSAGE',
      name: t('campaigns.steps.linkedin-message'),
      icon: 'linkedin-square',
      iconColor: '#EEF3F8',
      backgroundColor: '#0A66C2',
      component: CampaignsCreateStepsViewsLinkedinMessage,
      nextConditions: [
        getConditionFromKey('DELAY'),
      ],
    },
    {
      key: 'LINKEDIN_INMAIL_RECRUITER',
      name: t('campaigns.steps.linkedin-inmail-recruiter'),
      icon: 'linkedin-square',
      iconColor: '#FFF3E0',
      backgroundColor: '#F4A534',
      component: CampaignsCreateStepsViewsLinkedinInmailRecruiter,
      nextConditions: [
        getConditionFromKey('DELAY'),
      ],
    },
    {
      key: 'LINKEDIN_INMAIL_SALES_NAVIGATOR',
      name: t('campaigns.steps.linkedin-inmail-sales'),
      icon: 'linkedin-square',
      iconColor: '#FFF3E0',
      backgroundColor: '#F4A534',
      component: CampaignsCreateStepsViewsLinkedinInmailSales,
      nextConditions: [
        getConditionFromKey('DELAY'),
      ],
    },
    {
      key: 'LINKEDIN_INMAIL_PREMIUM',
      name: t('campaigns.steps.linkedin-inmail-premium'),
      icon: 'linkedin-square',
      iconColor: '#FFF3E0',
      backgroundColor: '#F4A534',
      component: CampaignsCreateStepsViewsLinkedinInmailPremium,
      nextConditions: [
        getConditionFromKey('DELAY'),
      ],
    },
    {
      key: 'LINKEDIN_PROFILE_VISIT',
      name: t('campaigns.steps.linkedin-profile-visit'),
      icon: 'eye',
      iconColor: '#EEF3F8',
      backgroundColor: '#0A66C2',
      component: CampaignsCreateStepsViewsLinkedinProfileVisit,
      nextConditions: [
        getConditionFromKey('DELAY'),
      ],
    },
    {
      key: 'LINKEDIN_VOICE_NOTE',
      name: t('campaigns.steps.linkedin-voice-note'),
      icon: 'microphone',
      iconColor: '#EEF3F8',
      backgroundColor: '#0A66C2',
      upcoming: true,
      component: CampaignsCreateStepsViewsLinkedinInvitation,
      nextConditions: [
        getConditionFromKey('DELAY'),
      ],
    },
    {
      key: 'SEND_MAIL',
      name: t('campaigns.steps.send-email'),
      icon: 'mail',
      iconColor: '#FFF3E0',
      backgroundColor: '#F4A534',
      upcoming: true,
      component: CampaignsCreateStepsViewsSendMail,
      nextConditions: [
        getConditionFromKey('DELAY'),
      ],
    },
  ]);

  return {
    steps,
  };
};
