<script lang="ts" setup>
import LinkedinMessageTimelinePreview from '@/components/Panel/PanelCoder/PanelCoderTemp/LinkedinMessageTimelinePreview.vue';
import { linkedinMacros } from '@/macros/linkedin';
import type { LinkedinTemplateTypeEnum } from '@/composables/form/useLinkedinBaseForm';
import { computed } from 'vue';
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar.vue';
const props = defineProps<{
  action: any;
}>();

const campaignActionTypeToLinkedinType: Record<string, LinkedinTemplateTypeEnum> = {
  LINKEDIN_MESSAGE: linkedinMacros.templates.type.message,
  LINKEDIN_INVITATION: linkedinMacros.templates.type.invitation,
  LINKEDIN_INMAIL_RECRUITER: linkedinMacros.templates.type.inmail,
  LINKEDIN_INMAIL_SALES: linkedinMacros.templates.type.inmail,
  LINKEDIN_INMAIL_PREMIUM: linkedinMacros.templates.type.inmail,
};

const messageType = computed(() => {
  return campaignActionTypeToLinkedinType[props.action.prospectAction.campaignAction.type];
});
</script>

<template>
  <div class="flex w-full flex-col rounded-lg border border-blue-200 bg-white">
    <div class="flex items-center gap-2.5 px-[15px] py-[8px]">
      <i class="icon-clock text-blue-800" />
      <!-- link to campaign -->
      <RouterLink
        :to="{ name: 'Prospects', query: { campaignId: action.campaign._id, currentActionId: action.prospectAction._campaignAction } }">
        <p class="text-xs text-blue-800">{{ action.title }} <span class="text-blue-500">{{ action.campaignName
        }}</span></p>
      </RouterLink>
    </div>
    <div class="flex items-center gap-2.5 bg-[#EAEDFE] px-[10px] py-[4px]">
      <RouterLink
        :to="{ query: { ...$route.query, type: 'coaches' }, params: { ...$route.params, id: action.campaign.coach._id } }">
        <BcAvatar :src="action.campaign.coach.pictureUrl" size="s" class="shrink-0" />
      </RouterLink>
      <p class="text-ellipsis text-xs text-blue-800">
        <RouterLink
          :to="{ query: { ...$route.query, type: 'coaches' }, params: { ...$route.params, id: action.campaign.coach._id } }">
          <span class="text-blue-500">{{ action.campaign.coach.firstName }} {{ action.campaign.coach.lastName
          }}</span>
        </RouterLink>
        <span>
          {{ action.scheduledText }}
        </span>
        <RouterLink
          :to="{ query: { ...$route.query, type: 'coders' }, params: { ...$route.params, id: action.coder._id } }">
          <span class="text-blue-500">{{ action.coder.firstName }} {{ action.coder.lastName }}</span>
        </RouterLink>
      </p>
    </div>
    <LinkedinMessageTimelinePreview v-if="messageType" :type="messageType" :subject="action.subject"
      :body="action.body" :coder="action.coder" />
    <!-- <button
                v-if="action.text.length > 200"
                @click="toggleMessage(action.prospectAction._id)"
                class="flex w-fit items-center gap-1 text-sm text-blue-800 underline"
              >
                {{ expandedMessages[action.prospectAction._campaignAction] ? t('timeline.see-less') : t('timeline.see-more') }}
                <i :class="['icon-chevron-' + (expandedMessages[action.prospectAction._campaignAction] ? 'up' : 'down'), 'text-xs']" />
              </button> -->
  </div>
</template>