<script lang="ts" setup>
  import { Component, computed, ref } from 'vue';
  import { CampaignProspectOptions, Tab } from '@/components/Campaigns/CampaignsTypes';
  import CampaignsCreateStepsItem
    from '@/components/Campaigns/CampaignsCreate/CampaignsCreateSteps/CampaignsCreateStepsItem.vue';
  import { useI18n } from '@/i18n/i18n';
  import { capitalize } from '@/utils/stringTransform';
  import CampaignsCreateProspectsPlaceholder
    from '@/components/Campaigns/CampaignsCreate/CampaignsCreateProspects/CampaignsCreateProspectsPlaceholder.vue';
  import CampaignsCreateProspectsFromList
    from '@/components/Campaigns/CampaignsCreate/CampaignsCreateProspects/CampaignsCreateProspectsFromList.vue';
  import CampaignsCreateProspectsItem
    from '@/components/Campaigns/CampaignsCreate/CampaignsCreateProspects/CampaignsCreateProspectsItem.vue';
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import { useMutation } from '@tanstack/vue-query';
  import { createCampaignProspectSource, resolveProspectsFromSources } from '@/api/campaigns';
  import { Campaign, CampaignProspectSource } from '@/domains/models/Campaign';
  import { useStore } from '@/store';

  const props = defineProps<{
    campaign: Campaign;
    tabs: Tab[];
    activeTab: Tab;
    activeTabIndex: number;
    nextTab: Tab;
  }>();
  const { t } = useI18n();

  const emit = defineEmits<{
    (e: 'next'): void;
  }>();

  const store = useStore();
  const cards = computed(() => store.state.card.cards);

  const options = computed<CampaignProspectOptions[]>(() => [
    {
      key: 'from-lists',
      name: capitalize(t('campaigns.prospects.from-my-lists')),
      icon: 'list',
      component: CampaignsCreateProspectsFromList,
    },
    {
      key: 'from-marvin-search',
      name: capitalize(t('campaigns.prospects.search-on-marvin')),
      icon: 'search',
      upcoming: true,
    },
    {
      key: 'from-linkedin-recruiter-project',
      name: capitalize(t('campaigns.prospects.import-a-linkedin-recruiter-project')),
      icon: 'linkedin-square',
      iconColor: '#0A66C2',
      upcoming: true,
    },
    {
      key: 'from-linkedin-search',
      name: capitalize(t('campaigns.prospects.search-on-linkedin')),
      icon: 'linkedin-square',
      iconColor: '#0A66C2',
      upcoming: true,
    },
  ]);

  const selectedView = ref<Component>(CampaignsCreateProspectsPlaceholder);
  const selectedLists = ref(props.campaign.prospectsSources ?? []);

  function createNewProspects(component: Component) {
    if (component) {
      isOpen.value = false;
      selectedView.value = component;
    }
  }

  const addStepButton = computed(() => ({
    name: capitalize(t('generics.add-resource', { resource: t('campaigns.more-prospects') })),
    icon: 'plus',
  }));
  const isOpen = ref(false);

  function toggleOpen() {
    isOpen.value = !isOpen.value;
  }

  function resetView() {
    selectedView.value = CampaignsCreateProspectsPlaceholder;
  }

  function handleUpdateProspects(prospects: any) {
    selectedLists.value.push(prospects);
    resetView();
  }

  function removeList(index: number) {
    selectedLists.value.splice(index, 1);
  }

  const createCampaignProspectsSources = useMutation({
    mutationKey: ['create-campaign-prospects-sources', props.campaign?._id ?? ''],
    mutationFn: async() => {
      const prospectsSourcesToCreate = selectedLists.value.filter((prospectsSource: CampaignProspectSource) => !prospectsSource._id);

      for (const prospectsSource of prospectsSourcesToCreate) {
        await createCampaignProspectSource(props.campaign._id, {
          type: prospectsSource.type,
          name: prospectsSource.name,
          ...(prospectsSource._list && { _list: prospectsSource._list._id }),
        });
      }

      const prospects = await resolveProspectsFromSources(props.campaign._id);

      const backgroundCardIndex = await store.dispatch('updateCardFromId', {
        id: props.campaign._id,
        content: props.campaign,
      });

      if (backgroundCardIndex < 0) {
        await store.dispatch('unshiftCard', props.campaign);
      }
    },
  });

  async function handleNextButton() {
    await createCampaignProspectsSources.mutateAsync();

    emit('next');
  }
</script>

<template>
  <div class="flex grow">
    <div
      class="flex w-full max-w-[345px] shrink-0 grow flex-col gap-[5px] border border-blue-100 bg-neutral-100 p-[20px]">
      <CampaignsCreateProspectsItem
        v-for="(list, index) in selectedLists"
        :key="`${list.type}-${index}`"
        :is-draggable="true"
        :list="list"
        @delete="removeList(index)"
      />
      <div
        v-click-outside="() => isOpen = false"
        class="relative flex w-full flex-col"
      >
        <CampaignsCreateStepsItem
          :action="addStepButton"
          :is-draggable="false"
          class="w-full !border-0 !bg-blue-100 !text-blue-500"
          @click.native="toggleOpen"/>
        <transition name="slide-fade">
          <div
            v-show="isOpen"
            class="mt-2 flex-col overflow-hidden rounded border-blue-100 shadow"
          >
            <button
              v-for="option in options"
              :key="option.key"
              :disabled="option.upcoming"
              class="relative flex h-10 w-full items-center gap-2.5 border-b border-blue-100 bg-white p-[20px] last:border-b-0 hover:enabled:bg-blue-100 hover:enabled:text-blue-500 disabled:cursor-default"
              @click="createNewProspects(option.component)">
              <div :class="{ 'opacity-50': option.upcoming }" class="flex grow items-center gap-2.5">
                <i
                  :class="`icon-${option.icon}`"
                  class="flex size-[15px] items-center justify-center rounded-full text-[10px]"
                  :style="{ color: option.iconColor }"
                />
                <p class="flex-start flex grow whitespace-nowrap">{{ option.name }}</p>
              </div>
              <div 
                v-if="option.upcoming" 
                class="absolute right-[-42px] bottom-[-19px] bg-red-500 py-[1px] w-[100px] text-center rotate-[-30deg] origin-bottom-left justify-center"
              >
                <span class="text-xs font-medium text-white whitespace-nowrap">{{ $t('generics.soon') }}</span>
              </div>
            </button>
          </div>
        </transition>
      </div>
      <BcButton
        class="mt-auto !w-full max-w-[300px]"
        size="small"
        @click.native="handleNextButton"
      >
        {{ $t('generics.next') }}
      </BcButton>
    </div>
    <div class="flex grow flex-col p-[20px]">
      <component
        :is="selectedView"
        v-if="selectedView"
        @update-prospects="handleUpdateProspects"/>
    </div>
  </div>
</template>
