<script lang="ts" setup>
import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner.vue';
import { computed, ref, onMounted } from 'vue';
import { useTimeoutFn } from '@vueuse/core';
import TimelineItemWrapper
  from '@/components/Panel/PanelCoder/PanelCoderTemp/TimelineItemWrapperTemp.vue';
import { addDays, formatDistanceToNow, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar.vue';
import { useStore } from '@/store';
import { getNextProspectActions } from '@/api/coders.js';
import { watch } from 'vue';
import { useI18n } from '@/i18n/i18n';
import { linkedinMacros } from '@/macros/linkedin';
import PanelCoderProspectsTimelineItem from '@/components/Panel/PanelCoder/PanelCoderTemp/PanelCoderProspectsTimelineItem.vue';

const isLoading = ref(true);
const store = useStore();
const coach = computed(() => store.state.user.profile);
const formattedNextActions = ref<any[]>([]);
const { t } = useI18n();
// const expandedMessages = ref<Record<string, boolean>>({});

const props = defineProps<{
  coder: any
}>();


const campaignActionTypeTranslationMap = {
  LINKEDIN_PROFILE_VISIT: 'campaigns.scheduled.linkedin_profile_visit',
  LINKEDIN_MESSAGE: 'campaigns.scheduled.linkedin_message',
  LINKEDIN_INVITATION: 'campaigns.scheduled.linkedin_invitation',
  LINKEDIN_INMAIL_RECRUITER: 'campaigns.scheduled.linkedin_inmail_recruiter',
  LINKEDIN_INMAIL_SALES: 'campaigns.scheduled.linkedin_inmail_sales',
  LINKEDIN_INMAIL_PREMIUM: 'campaigns.scheduled.linkedin_inmail_premium',
  LINKEDIN_VOICE_NOTE: 'campaigns.scheduled.linkedin_voice_note',
  SEND_MAIL: 'campaigns.scheduled.send_mail',
} as const;

const toFormattedActions = (actions: any[]) => {
  return actions.map((action) => {
    return {
      ...action,
      title: t('timeline.campaign-action-planned'),
      campaignName: action.campaign.name,
      rawDate: undefined,
      // date: formatDistanceToNow(undefined, { addSuffix: true, locale: fr }),
      scheduledText: campaignActionTypeTranslationMap[action.prospectAction.campaignAction.type] ? t(campaignActionTypeTranslationMap[action.prospectAction.campaignAction.type]) : '',
      icon: 'send',
      body: action.prospectAction.body || action.prospectAction.campaignAction.body,
      subject: action.prospectAction.subject || action.prospectAction.campaignAction.subject,
    };
  });
};

const fetchProspectActions = async () => {
  try {
    const response = await getNextProspectActions(props.coder._id);

    formattedNextActions.value = toFormattedActions(response.data);
  } catch (error) {
    console.error('Failed to fetch prospect actions:', error);
    formattedNextActions.value = [];
  } finally {
    isLoading.value = false;
  }
};

watch(
  () => props.coder,
  () => {
    if (props.coder?._id) {
      fetchProspectActions();
    }
  },
  { immediate: true }
);

// const toggleMessage = (actionId: string) => {
//   expandedMessages.value[actionId] = !expandedMessages.value[actionId];
// };
</script>

<template>
  <div class="flex w-full flex-col items-center">
    <BcSpinner v-if="isLoading" size="large" />
    <template v-else>
      <TimelineItemWrapper v-for="(action, index) in formattedNextActions" :key="index" :action="action"
        class="mb-2.5 w-full">
        <PanelCoderProspectsTimelineItem :action="action" />
      </TimelineItemWrapper>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
