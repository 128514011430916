<script lang="ts" setup>
import Views from '@/components/Views/Views.vue';
import ButtonBack from '@/components/Button/ButtonBack.vue';
import { computed, ref } from 'vue';
import CampaignsCardType from '@/components/Campaigns/CampaignsCard/CampaignsCardType.vue';
import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
import { capitalize } from '@/utils/stringTransform';
import BcDropdownItem from '@/ui-kit/components/BcDropdown/BcDropdownItem.vue';
import BcDropdown from '@/ui-kit/components/BcDropdown/BcDropdown.vue';
import { useI18n } from '@/i18n/i18n';
import { addDays, addMonths, addWeeks, format } from 'date-fns';
import ProspectsTabs from '@/views/Tabs/ProspectsTabs.vue';
import ProspectsFilters from '@/components/Prospects/ProspectsFilters.vue';
import ProspectsStatusTabs from '@/components/Prospects/ProspectsStatusTabs.vue';
import ProspectsGrid from '@/components/Prospects/ProspectsGrid.vue';
import ProspectsCardsList from '@/components/Prospects/ProspectsCardsList.vue';
import { useRouter } from 'vue-router/composables';
import { useQuery, useInfiniteQuery } from '@tanstack/vue-query';
import { getCampaignProspects, getCampaign } from '@/api/campaigns';
import { useRoute } from 'vue-router/composables';
import { startCampaign, resumeCampaign, pauseCampaign, scheduleCampaign } from '@/api/campaigns';
import { usePanelStore } from '@/store/pinia/panel';
import CampaignsCreateView from '@/components/Campaigns/CampaignsCreate/CampaignsCreateView.vue';
import type { Campaign, CampaignProspect } from '@/domains/models/Campaign';
import CampaignsCardStatus from '@/components/Campaigns/CampaignsCard/CampaignsCardStatus.vue';

const route = useRoute();
const panelStore = usePanelStore();

const campaignId = computed(() => route.query['campaignId'] as string | undefined);
const currentActionId = computed(() => route.query['currentActionId'] as string | undefined);

const prospectsRouteQueries = computed(() => ({
  ...campaignId.value && { campaignId: campaignId.value },
  ...currentActionId.value && { currentActionId: currentActionId.value },
}));

interface ProspectsPage {
  items: CampaignProspect[];
  cursor?: string;
}

interface InfiniteProspectsData {
  pages: ProspectsPage[];
  pageParams: (string | undefined)[];
}

const {
  data: prospectsPages,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
} = useInfiniteQuery<ProspectsPage, Error, InfiniteProspectsData>({
  queryKey: ['prospects', prospectsRouteQueries],
  queryFn: async ({ pageParam }) => {
    const response = await getCampaignProspects({
      ...prospectsRouteQueries.value,
      cursor: pageParam as string | undefined,
    });
    return response.data;
  },
  getNextPageParam: (lastPage) => lastPage.cursor ?? null,
  initialPageParam: undefined,
});

const prospects = computed(() =>
  prospectsPages.value?.pages?.flatMap((page: ProspectsPage) => page.items) ?? []
);

const campaign = ref<Campaign>();

const { data: initialCampaign } = useQuery({
  queryKey: ['campaign', campaignId],
  queryFn: async () => {
    if (!campaignId.value) {
      return null;
    }
    const response = await getCampaign(campaignId.value);
    campaign.value = response.data;
    return response.data;
  },
});

const { t } = useI18n();
const router = useRouter();

const dateOptions = computed<{
  name: () => string;
  date: Date | string;
}[]>(() => [
  {
    name: t('companies.panel-view-timeline.none'),
    date: t('companies.panel-view-timeline.none-min'),
  },
  {
    name: `${t('hunt.in-two-days')} (${format(addDays(new Date(), 2), 'P')})`,
    date: addDays(new Date(), 2),
  },
  {
    name: `${t('hunt.in-one-week')} (${format(addWeeks(new Date(), 1), 'P')})`,
    date: addWeeks(new Date(), 1),
  },
  {
    name: `${t('hunt.in-three-months')} (${format(addMonths(new Date(), 3), 'P')})`,
    date: addMonths(new Date(), 3),
  },
  {
    name: `${t('hunt.in-six-months')} (${format(addMonths(new Date(), 6), 'P')})`,
    date: addMonths(new Date(), 6),
  },
  {
    name: `${t('hunt.in-nine-months')} (${format(addMonths(new Date(), 9), 'P')})`,
    date: addMonths(new Date(), 9),
  },
]);

const settingsOptions = computed<{
  key: string;
  name: () => string;
  icon: string;
}[]>(() => [
  {
    key: 'edit',
    name: t('generics.edit'),
    icon: 'edit-3',
  },
  {
    key: 'duplicate',
    name: t('generics.duplicate'),
    icon: 'file-copy',
  },
  {
    key: 'terminate',
    name: capitalize(t('generics.terminate')),
    icon: 'archive',
  },
  {
    key: 'delete',
    name: t('generics.delete'),
    icon: 'trash',
    color: 'danger',
  },
]);

function goToCampaigns() {
  router.push({ name: 'Campaigns' });
}

function handleOptionClick(option: string) {
  if (option === 'edit') {
    handleEdit();
  }
}

function handleEdit() {
  panelStore.openModal(CampaignsCreateView, {
    campaign: campaign.value ?? {},
  });
}

async function handleStartCampaign() {
  if (!campaignId.value) return;
  const response = await startCampaign(campaignId.value);
  campaign.value = { ...campaign.value, ...response.data };
}

async function handleResumeCampaign() {
  if (!campaignId.value) return;
  const response = await resumeCampaign(campaignId.value);
  campaign.value = { ...campaign.value, ...response.data };
}

async function handlePauseCampaign() {
  if (!campaignId.value) return;
  const response = await pauseCampaign(campaignId.value);
  campaign.value = { ...campaign.value, ...response.data };
}

async function handleScheduleCampaign(scheduledAt: Date) {
  if (!campaignId.value) return;
  const response = await scheduleCampaign(campaignId.value, scheduledAt);
  campaign.value = { ...campaign.value, ...response.data };
}

function handleDateOptionClick(option: { date: Date | string }) {
  if (option.date instanceof Date) {
    handleScheduleCampaign(option.date);
  }
}

</script>

<template>
  <views class="database-campaigns flex w-full shrink-0 flex-col !items-start overflow-auto">
    <div
      class="flex w-full min-w-[1180px] items-center justify-between border-b border-b-blue-200 bg-neutral-200 px-[30px] py-2.5">
      <div class="flex gap-5">
        <ButtonBack @click="goToCampaigns">
          <p class="first-letter:capitalize">
            {{ $t('generics.back-to-resources', { resources: $tc('campaigns.to-campaigns', 2) }) }}
          </p>
        </ButtonBack>
        <div class="flex items-center gap-2.5">
          <h1 class="text-xl font-bold text-blue-800">{{ campaign?.name }}</h1>
          <CampaignsCardType v-if="campaign?.campaignTypes?.length" :type="campaign.campaignTypes[0].name" />
          <CampaignsCardStatus v-if="campaign" :campaign="campaign" />
        </div>
      </div>
      <div class="flex gap-2.5">
        <BcDropdown v-if="campaign?.status === 'SCHEDULED' || campaign?.status === 'DRAFT'" :options="dateOptions"
          class="flex shrink-0" position="bottom-left">
          <template #label="{ isDropdownOpen }">
            <BcButton icon-left="calendar" class="h-9 !text-sm" type="outlined" @click.prevent>
              {{ campaign?.status === 'SCHEDULED' && campaign?.scheduledAt ? capitalize($t('campaigns.reschedule')) :
                capitalize($t('generics.to-program')) }}
            </BcButton>
          </template>
          <template #option="{ activeIndex, index, option }">
            <BcDropdownItem :icon="option.icon" :is-focus="activeIndex === index" :is-selected="false"
              :text="option.name" :title="option.name" @click.native="handleDateOptionClick(option)">
            </BcDropdownItem>
          </template>
        </BcDropdown>
        <BcButton v-if="campaign?.status === 'DRAFT' || campaign?.status === 'SCHEDULED'" class="h-9 !text-sm"
          color="success" :disabled="!campaign?._prospectsSources?.length || !campaign?._actions?.length"
          :v-tooltip="!campaign?._prospectsSources?.length ? 'Vous devez ajouter des prospects' : 'Vous devez ajouter des actions'"
          icon-left="send" size="extra-small" @click="handleStartCampaign">
          {{ campaign?.status === 'SCHEDULED' && campaign?.scheduledAt ? capitalize($t('campaigns.start-now')) :
            capitalize($t('campaigns.start-my-campaign')) }}
        </BcButton>
        <BcButton v-if="campaign?.status === 'PAUSED'" class="h-9 !text-sm" color="success"
          :disabled="!campaign?._prospectsSources?.length || !campaign?._actions?.length"
          :v-tooltip="!campaign?._prospectsSources?.length ? 'Vous devez ajouter des prospects' : 'Vous devez ajouter des actions'"
          icon-left="send" size="extra-small" @click="handleResumeCampaign">
          {{ capitalize($t('campaigns.resume-my-campaign')) }}
        </BcButton>
        <BcButton v-if="campaign?.status === 'ONGOING'" class="h-9 !text-sm" type="outlined" color="success"
          :v-tooltip="!campaign?._prospectsSources?.length ? 'Vous devez ajouter des prospects' : 'Vous devez ajouter des actions'"
          icon-left="pause" size="extra-small" @click="handlePauseCampaign">
          {{ capitalize($t('campaigns.pause-my-campaign')) }}
        </BcButton>
        <BcDropdown :options="settingsOptions" position="bottom-left">
          <template #label="{ isDropdownOpen }">
            <BcButton icon-left="settings" size="small" @click.prevent>
            </BcButton>
          </template>
          <template #option="{ activeIndex, index, option }">
            <BcDropdownItem :class="option.class" :color="option.color" :icon="option.icon"
              :is-focus="activeIndex === index" :is-selected="false" :text="option.name" :title="option.name"
              @click.native="handleOptionClick(option.key)">
            </BcDropdownItem>
          </template>
        </BcDropdown>
      </div>
    </div>
    <div class="flex w-full min-w-[1180px]">
      <ProspectsTabs>
        <ProspectsFilters />
      </ProspectsTabs>
    </div>
    <RouterView />
    <div v-if="campaign?._actions?.length" class="flex w-full min-w-[1180px] flex-col px-[30px] py-[20px]">
      <ProspectsStatusTabs :campaign="campaign" class="mb-[20px]" />
      <ProspectsGrid />
      <ProspectsCardsList :cards="prospects" :has-more="hasNextPage" :is-loading-more="isFetchingNextPage"
        @load-more="fetchNextPage" />
    </div>
  </views>
</template>

<style lang="scss" scoped>
.database-campaigns {
  @include page_grid();
}
</style>
