<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useLinkedinSendForm } from '@/composables/form/useLinkedinSendForm';
import type { LinkedinTemplateTypeEnum } from '@/composables/form/useLinkedinBaseForm';
import { linkedinMacros } from '@/macros/linkedin';
import { useStore } from '@/store';
import { useI18n } from '@/i18n/i18n';
import { useQuery } from '@tanstack/vue-query';

const props = defineProps<{
  type: LinkedinTemplateTypeEnum;
  subject?: string;
  body: string;
  coder?: any;
}>();

const store = useStore();
const { t } = useI18n();

useQuery({
    queryKey: ['fetch-email-variables'],
    queryFn: async() => {
      return store.dispatch('emails/getTemplatesVariables');
    },
  });

const templatesVariablesFiltered = computed(() => {
    return templatesVariables.value.filter((template: { source: string }) => template.source === 'coder');
});

const messageVariables = computed(() => {
  return templatesVariablesFiltered.value.map(variable => ({
    ...variable,
    value: getVariableValue(variable)
  }));
});

function getVariableValue(variable: { source: string; slug: string }) {
  switch (variable.source) {
    case 'coder':
      return getNestedValue(props.coder, variable.slug);
    case 'customJob':
      return getNestedValue(props.customJob, variable.slug);
    case 'company':
      return getNestedValue(props.customJob?._company || {}, variable.slug);
    default:
      return undefined;
  }
}

function getNestedValue(obj: any, path: string): any {
  return path.split('.').reduce((acc, part) => acc?.[part], obj);
}

const templatesVariables = computed(() => store.state.emails.templatesVariables || []);

const { fieldErrors, missingVariables, missingUnknownVariables, preview } = useLinkedinSendForm(
  {
    type: props.type,
    subject: props.subject || '',
    body: props.body,
  },
  messageVariables
);
</script>

<template>
  <div v-if="preview.body || preview.subject" class="w-full flex flex-col p-2.5 gap-2 text-sm">
    <div class="flex flex-col w-full gap-2 p-4 pl-5 pr-7 border border-blue-200 rounded-[10px]">
      <div v-if="preview.subject" class="font-bold text-blue-800 whitespace-pre-line">{{ t('linkedin.inmail-subject') }}:
        {{ preview.subject }}</div>
      <div :class="['text-sm text-blue-800 whitespace-pre-line',
        // { 'line-clamp-3': !expandedMessages[action.prospectAction._campaignAction] }
      ]">
        {{ preview.body }}
      </div>
    </div>
    <ul
      class="flex flex-col gap-1.5 mx-1 text-red-500 text-xs">
      <li v-for="(error, field) in fieldErrors" :key="field" class="flex items-center gap-1">
         <span>{{ error }}</span>
      </li>
      <li v-for="variable in missingVariables" :key="variable">
        {{ t('linkedin.unable-to-send-message-because-the-variable') }}
        <span class="text-red-500">{{ variable }}*</span>
        {{ t('email.has-no-value') }}
      </li>
      <li v-for="variable in missingUnknownVariables" :key="variable">
        {{ t('linkedin.an-unknown-variable-has-been-used') }}
        <span class="text-red-500">{{ variable }}</span>
        {{ t('linkedin.please-check-this-is-not-a-typo') }}
      </li>
    </ul>
  </div>
</template>

