<script lang="ts" setup>
  import CampaignsTabs from '@/views/Tabs/CampaignsTabs.vue';
  import Views from '@/components/Views/Views.vue';
  import CampaignsFilters from '@/components/Campaigns/CampaignsFilters.vue';
  import CampaignsGrid from '@/components/Campaigns/CampaignsGrid.vue';
  import CampaignsCardsList from '@/components/Campaigns/CampaignsCardsList.vue';
  import { useQuery } from '@tanstack/vue-query';
  import { getCampaigns, GetCampaignsParams } from '@/api/campaigns';
  import { useRoute } from 'vue-router/composables';
  import { computed } from 'vue';
  import { useStore } from '@/store';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner.vue';
  import CampaignsMultiselectFooter from '@/components/Campaigns/CampaignsMultiselectFooter.vue';

  const route = useRoute();
  const store = useStore();

  const routeQueries = computed<GetCampaignsParams>(() => ({
    ...route.query['campaigns-search'] && { name: route.query['campaigns-search'] },
    ...route.query['campaigns-status'] && { status: route.query['campaigns-status'] },
    ...route.query['campaigns-coaches'] && { coaches: route.query['campaigns-coaches'] },
    ...route.query['campaigns-type'] && { campaignType: route.query['campaigns-type'] },
  }));

  const { isLoading } = useQuery({
    queryKey: ['campaigns', routeQueries],
    queryFn: async() => {
      await store.dispatch('resetCards');

      const response = await getCampaigns(routeQueries.value);

      await store.dispatch('setCards', response.data);
    },
  });

  const cards = computed(() => store.state.card.cards);
  const selectedCards = computed(() => store.state.card.selectedCards);
  const isAllCardsSelected = computed(() => store.state.card.cards === store.state.card.selectedCards);

  function handleSelectAllCampaign(value) {
    const selectedCards = value ? cards.value : [];

    store.dispatch('setSelectedCards', selectedCards);
  }
</script>

<template>
  <views class="database-campaigns flex w-full shrink-0 flex-col !items-start overflow-auto">
    <div class="flex w-full min-w-[1180px]">
      <CampaignsTabs>
        <CampaignsFilters/>
      </CampaignsTabs>
    </div>
    <RouterView/>
    <div class="flex w-full min-w-[1180px] flex-col px-[30px]">
      <CampaignsGrid
        :is-checked="isAllCardsSelected"
        @checked="handleSelectAllCampaign"/>
      <div
        v-if="isLoading"
        class="flex w-full justify-center py-5">
        <BcSpinner size="large"/>
      </div>
      <CampaignsCardsList
        v-else
        :cards="cards"/>
    </div>
    <CampaignsMultiselectFooter
      v-if="selectedCards.length"
      class="is-fixed is-bottom is-left is-full-width is-justify-content-center is-align-items-center is-column">
    </CampaignsMultiselectFooter>
  </views>
</template>

<style lang="scss" scoped>
  .database-campaigns {
    @include page_grid();
  }
</style>
